import users from './users'
import classes from './classes'
import courses from './courses'
import branches from './branches'
import administrations from './references'
import finance from './finance'
import testBase from './testBase'

export default [
  {
    title: 'others.home_page',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'student',
  },

  // // !For exaple
  // {
  //   header: 'Студент',
  // },
  // {
  //   title: 'Домашняя страница',
  //   route: 'student',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'класс лист',
  //   route: 'student-info-class-list',
  //   icon: 'BookOpenIcon',
  // },
  // {
  //   title: 'курс лист',
  //   route: 'student-info-course-list',
  //   icon: 'CodesandboxIcon',
  // },
  // // end
  ...administrations,
  ...users,
  ...finance,
  ...classes,
  ...courses,
  ...branches,
  ...testBase,
]
