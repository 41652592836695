<template>
  <b-nav-item @click="skin = isDark ? 'light' : 'dark'">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItem,
  },
  setup(props, { root }) {
    const { skin } = useAppConfig()
    const userData = useJwt.getUserData()
    skin.value = userData.theme === '0' ? 'dark' : 'light'
    const isDark = computed(() => {
      const theme = skin.value === 'dark'
      if (!userData) return theme
      const user = {
        id: userData.id,
        theme: skin.value === 'dark' ? '0' : '1',
      }
      if (!(userData.theme === (skin.value === 'dark' ? '0' : '1'))) {
        root.$store.dispatch('users/UPDATE_USER', user)
          .then(newUserData => {
            useJwt.setUserData(newUserData.data)
          })
      }
      return theme
    })

    return { skin, isDark }
  },
}
</script>
