export default [
  {
    header: 'others.branches',
  },
  {
    title: 'others.list_branches',
    route: 'branchesList',
    icon: 'BookIcon',
  },
  {
    title: 'others.moderations',
    route: 'branchesModeration',
    icon: 'CheckSquareIcon',
  },
]
