export default [
  {
    header: 'others.supervisor',
  },
  {
    title: 'others.class_list',
    route: 'class-list',
    icon: 'ClipboardIcon',
  },
]
