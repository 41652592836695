export default [
  {
    header: 'others.content_manager',
  },
  {
    title: 'others.content_base',
    route: 'content-base',
    icon: 'DatabaseIcon',
  },
  {
    title: 'others.course_administration',
    route: 'course-administrations',
    icon: 'BookIcon',
  },
]
