export default [
  {
    header: 'others.references',
  },
  {
    title: 'others.login_administration',
    route: 'web-settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'others.groups',
    route: 'groups',
    icon: 'SettingsIcon',
  },
  {
    title: 'references.subjects',
    route: 'subjects-list',
    icon: '',
  },
]
