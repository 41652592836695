export default [
  {
    header: 'tests.test_base',
  },
  {
    title: 'tests.test_base',
    route: 'test-base',
    icon: 'SettingsIcon',
  },
]
